import React, { useState, useContext } from "react"
import axios from 'axios'
import { navigate } from "gatsby"
import './../styles/login.css'
import { AppStateContext } from "../contexts/AppStateContext";

let axiosInstance = null;
const LoginPage = () => {

    const appState = useContext(AppStateContext);
    const [userName, setUserName] = useState("");
    const [userNameError, setUserNameError] = useState();
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState();

    const initAxiosInstance = () => {
        if (!axiosInstance) {
            axiosInstance = axios.create({
                baseURL: process.env.GATSBY_DEBIE_API_BASE_URI,
                timeout: 10000,
                // headers: {'X-Custom-Header': 'foobar'}
            });
        }
    }
   const loginRequest = () => {

      const loginErrors = getLoginErrors();
      if (loginErrors) {
        displayValidationErrors(loginErrors);
        return;
      }

      initAxiosInstance();
      const data = {
        username: userName,
        password: password,
      }
      axiosInstance.post('/business/login-1/', data)
        .then(function (response) {

          // handle success
          if (response.data && response.data.profile_uuid) {
            appState.profileUuid.set(response.data.profile_uuid);
            appState.loggedIn.set(true);
            appState.paymentPage.set(false);
            appState.plan.set(response.data.plan)
            // Clear Errors
            clearValidatonErrors();
            if(appState.redirect.value && appState.redirect.value.includes('account')){
              navigate('/account')
            }else{
              navigate('/dashboard');
            }

          } else {
            console.error("Invalid Response Received");
          }
        })
        .catch(function (error) {
          console.log(error);
          // handle error
          if (error.response && error.response.status && error.response.status === 400) {
            displayValidationErrors(error.response.data);
          }

        })
    }

    const getLoginErrors = () => {
        let errors = {};
        let foundError = false;
        // Check rquired Fields
        if (userName.trim() === "") {
            foundError = true;
            errors.username = "User name is required";
        }
        if (password.trim() === "") {
            foundError = true;
            errors.password = "Password is required";
        }
        if (foundError) {
            return errors;
        } else {
            return null;
        }
    }

    const displayValidationErrors = (errorsObject) => {
        const { username, password } = errorsObject;
        setUserNameError(username);
        setPasswordError(password);
    }

    const clearValidatonErrors = () => {
        setUserNameError(null);
        setPasswordError(null);
    }

    return (
        <div className="iniScreen-loginWrap">
         <div className="iniScreen-loginContainer">
            <div className="iniScreen-row-container">
               <div className="iniScreen-white-bg">
                  <div className="iniScreen-full-width-container">
                     <div className="iniScreen-nav-header">
                       <img width="120px" src="/images/debie-grey.svg" alt="" />
                     </div>
                     <div className="iniScreen-mt-30">
                        <h1 className="iniScreen-title">Sign In</h1>
                        <p className="iniScreen-sub-title1 mb-0">Please enter your credentials to proceed.</p>
                     </div>
                     <form id="form" method="post" onSubmit={(e) => { e.preventDefault(); loginRequest()}} >
                        <div className="iniScreen-mt-40">
                           <div className="iniScreen-email-container">
                              <div className="iniScreen-pt-10">
                                 <label className="iniScreen-sub-title" htmlFor="email">Email <span className="required">*</span>
                                 <div className="iniScreen-input-field">
                                    <input type="text" name="email" placeholder="Email" required="" id="id_username" value={userName} onChange={(e) => { setUserName(e.target.value) }} />
                                    {
                                       userNameError &&
                                       <span className="iniScreen-parsley-required">{userNameError}</span>
                                    }
                                 </div>
                                 </label>
                              </div>
                           </div>
                           <div className="iniScreen-email-container iniScreen-mt-20">
                              <div className="iniScreen-pt-10">
                                 <label className="iniScreen-sub-title"  htmlFor="password">Password <span className="required">*</span>
                                 <div className="iniScreen-input-field">
                                    <input type="password" name="password" placeholder="Password" required="" id="id_password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                                    {
                                       passwordError &&
                                       <span className="iniScreen-parsley-required">{passwordError}</span>
                                    }
                                 </div>
                                 </label>
                              </div>
                              <div className="iniScreen-pt-20">
                                 <span id="error-msg"></span>
                              </div>
                           </div>
                           <div className="iniScreen-text-center">
                           </div>
                           <div className="iniScreen-mt-30  iniScreen-text-left">
                              <input type="submit" className="iniScreen-btn iniScreen-btn-primary" value="Login" />
                           </div>
                           <div className="iniScreen-mt-15 iniScreen-text-left">
                              <p className="iniScreen-fs-size iniScreen-mt-5">
                                 Forgot login password?
                                 <a className="iniScreen-text-decoration-none iniScreen-green" href="https://dashboard.debieratings.com/accounts/password_reset/" target="_blank" rel="noopener noreferrer"> Reset Password </a>
                              </p>
                              <p className="iniScreen-fs-size">By Clicking "Login" you agree to our
                              	<a className="iniScreen-text-decoration-none" href="https://www.debieratings.com/debie-terms" target="_blank" rel="noopener noreferrer">
                              		<span className="iniScreen-green"> Terms of Service</span>
                              	</a> and
                                <a className="iniScreen-text-decoration-none" href="https://www.debieratings.com/debie-privacy-policy" target="_blank" rel="noopener noreferrer">
                                	<span className="iniScreen-green"> Privacy Policy</span>
                                </a>
                              </p>
                           </div>
                        </div>
                     </form>
                  </div>
               </div>
               <div className="iniScreen-green-bg iniScreen-text-center iniScreen-mt-100">
                  <img width="80%" src="/images/debiee.png" alt="debie.png" />
               </div>
            </div>
         </div>
      </div>
    )
}

export default LoginPage
